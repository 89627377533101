import { message, MessageInfoOptions } from 'tdesign';
import { Toast } from 'tdesign-mobile-react';
import JSRuntime from 'common/utils/JSRuntime';
import Icon from '../Icon';
import MessageScss from './Message.module.scss';

enum TOAST_TYPE {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

message.config({
  duration: 3000,
  placement: 'bottom-right',
  closeBtn: true,
  icon: false,
  className: MessageScss.message,
});

const getConfig = (msg: string | MessageInfoOptions) => ({
  ...(_.isString(msg) ? {} : msg),
  content: (
    <span style={{ flex: '1 1 auto', whiteSpace: 'pre-wrap' }}>
      {_.isString(msg) ? msg : _.get(msg, 'content')}
    </span>
  ),
});

const toast = (msg?: string | MessageInfoOptions, type?: TOAST_TYPE, duration?: number) => {
  let iconType = '';
  switch (type) {
    case TOAST_TYPE.SUCCESS:
      iconType = 'TxCircle';
      break;
    case TOAST_TYPE.ERROR:
      iconType = 'TxError';
      break;
    case TOAST_TYPE.WARNING:
      iconType = 'TxNotice';
      break;
    default:
      break;
  }

  return Toast({
    message: _.isString(msg) ? msg : _.get(msg, 'content'),
    duration: duration ?? 2000,
    preventScrollThrough: false, // 居然是个必传
    placement: 'middle',
    icon: <Icon type={iconType} size={32} className={MessageScss.toastIcon} />,
    direction: 'column',
  });
};

const success = (msg: string | MessageInfoOptions, duration?: number) => {
  const config = {
    ...getConfig(msg),
    icon: <Icon type="TxCircle" size={24} />,
  };
  return JSRuntime.isMobileUI
    ? toast(msg, TOAST_TYPE.SUCCESS, duration)
    : message.success(config, duration);
};

const warning = (msg: string | MessageInfoOptions, duration?: number) => {
  const config = {
    ...getConfig(msg),
    icon: <Icon type="TxNotice" size={24} />,
  };
  return JSRuntime.isMobileUI
    ? toast(msg, TOAST_TYPE.WARNING, duration)
    : message.warning(config, duration);
};

const error = (msg: string | MessageInfoOptions, duration?: number) => {
  const config = {
    ...getConfig(msg),
    icon: <Icon type="TxError" size={24} />,
  };
  return JSRuntime.isMobileUI
    ? toast(msg, TOAST_TYPE.ERROR, duration)
    : message.error(config, duration);
};

export default {
  success,
  warning,
  error,
};
