import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { AuthnApi } from 'idpBase/api';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { INVITATION_ERROR } from './constants/Constants';
import { LOGIN_RES_TYPE } from '../constants/Constants';
import { IHandshake } from '../constants/Types';

class InvitationStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<InvitationStore>;
  public resetStore: () => void;

  public isInvitationListLoading = true;
  public handshakes: IHandshake[] = [];
  public selectedHandshakeIds: string[] = [];
  public joinVisible = false;

  public isJoinLoading = false;

  public get accountsResult() {
    const selectedAccounts = [] as string[];
    const rejectAccounts = [] as string[];
    const rejectAccountIds = [] as string[];
    this.handshakes.forEach((item) => {
      if (this.selectedHandshakeIds.includes(item.handshakeId)) {
        selectedAccounts.push(item.companyName);
      } else {
        rejectAccounts.push(item.companyName);
        rejectAccountIds.push(item.handshakeId);
      }
    });
    return {
      selectedAccounts,
      rejectAccounts,
      rejectAccountIds,
      hasSelectedAccounts: selectedAccounts.length > 0,
      hasRejectAccounts: rejectAccounts.length > 0,
    };
  }

  // 刷新邀请(handshakes)列表
  public getInvitations() {
    this.updateStore({ isInvitationListLoading: true });
    return AuthnApi.getHandShakes()
      .then((res) => {
        const handshakes = m.get(res, 'handshakes') || [];
        this.updateStore({
          handshakes,
          selectedHandshakeIds: handshakes.map((item: IHandshake) => item?.handshakeId),
          isInvitationListLoading: false,
        });
        return Promise.resolve(handshakes);
      })
      .catch((err) => {
        this.updateStore({ isInvitationListLoading: false });
        this.global.handleError(err);
        return Promise.reject(err);
      });
  }

  private async handleInvitationsError(error: Error) {
    const status = _.get(error, 'status');
    const tokenExpireError = INVITATION_ERROR[status];
    const overTimeErrMsg = INVITATION_ERROR[m.get(error, 'data.errCode')]; // 邀请失效
    this.updateStore({ isJoinLoading: false });
    this.global.handleError(tokenExpireError || overTimeErrMsg || error);
    if (tokenExpireError) {
      // 停留时间过长,401,不再刷新invitations列表
      return;
    }
    // 加入出现失败，可能加入成功一部分，所以需要刷新列表
    await this.getInvitations();
    return;
  }

  // 接受邀请
  public acceptInvitations(res: Response) {
    const baseStore = this.global.login.loginBaseStore;
    baseStore.updateStore({
      accountOptions: m.get(res, 'next.accountOptions'),
      nextType: m.get(res, 'next.type'),
    });
    baseStore.redirectTo?.('/login/choose_account');
  }

  // 拒绝邀请
  public async rejectInvitation(res: Response) {
    const baseStore = this.global.login.loginBaseStore;
    const resultType = _.get(res, 'next.type');
    // 拒绝成功，剩下的是需要加入的企业
    if (resultType === LOGIN_RES_TYPE.INVITATION_OPTIONS) {
      return true;
    }
    // 拒绝成功，没有需要加入的企业
    baseStore.updateStore({
      accountOptions: _.get(res, 'next.accountOptions') || { accounts: [], unavailable: [] },
      handshakeOptions: _.get(res, 'next.handshakeOptions') || { handshakes: [] },
      nextType: resultType,
    });
    // 跳转到租户选择页
    baseStore.redirectTo?.('/login/choose_account');
    return false;
  }

  public async onJoin() {
    const baseStore = this.global.login.loginBaseStore;
    const { mobile } = baseStore;
    this.isJoinLoading = true;
    const { hasRejectAccounts, hasSelectedAccounts, rejectAccountIds } = this.accountsResult;
    /* 1.拒绝邀请逻辑 */
    if (hasRejectAccounts) {
      const [rejectErr, rejectResult] = await Utils.resolvePromise(
        AuthnApi.rejectInvitation(rejectAccountIds, JSRuntime.backEndApp),
      );

      if (rejectErr) {
        await this.handleInvitationsError(rejectErr);
        return;
      }

      this.updateStore({ isJoinLoading: false });
      if (!this.rejectInvitation(rejectResult)) return;
    }
    /* 2.接受邀请逻辑 */
    if (!hasSelectedAccounts) {
      baseStore.redirectTo?.('/login/choose_account');
      return;
    }

    // NOTICE: 当前产品逻辑，邮箱登录不会有 邀请页，不需要关注 INVITE_FROM.EMAIL 的情况
    const invitationMaps = this.selectedHandshakeIds?.map((id) => ({
      mobile, // NOTICE：确保进入 invitation 的场景都会有 mobile | email
      handshakeId: id,
    }));
    const [acceptErr, acceptResult] = await Utils.resolvePromise(
      AuthnApi.acceptInvitation(invitationMaps, JSRuntime.backEndApp),
    );
    if (acceptErr) {
      await this.handleInvitationsError(acceptErr);
      return;
    }

    this.updateStore({ isJoinLoading: false });
    this.acceptInvitations(acceptResult);
  }
}

export default InvitationStore;
