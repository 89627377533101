import { observer } from 'mobx-react';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useStore } from 'idpBase/store';

/**
 * 依赖登录态的页面都需要用这个包裹
 * @param PageComponent 页面组件
 */
const withLogin = (PageComponent: FC) => {
  return observer((props: any) => {
    const { rootStore } = useStore();

    const initGlobalData = async () => {
      const userInfo = await rootStore.getUserInfo();
      if (!userInfo) return;

      const account = await rootStore.getAccountInfo();

      rootStore.getJoinRoles();
      rootStore.getSettings({ accountId: account.accountId });
    };

    useEffect(() => {
      initGlobalData();
    }, []);

    // 用户信息 + 企业信息获取成功后再渲染页面
    if (
      _.isEmpty(mobx.toJS(rootStore.userInfo.accountUser)) ||
      _.isEmpty(mobx.toJS(rootStore.account))
    )
      return null;

    return <PageComponent {...props} />;
  });
};

export default withLogin;
