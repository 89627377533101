import { AxiosInstancePlus } from '@tencent/oneid-request';
import { TCaptchaVerification } from '@tencent/oneid-utils';
import { http } from 'common/http';

class AuthnApi {
  public axiosInstance: AxiosInstancePlus = http;

  public prefixURL = '/v1/authn';

  /**
   * authCode换登录态
   */
  getAuthCodeSession(data: Record<string, any>) {
    return this.axiosInstance.post(`${this.prefixURL}/auth_code/session`, data);
  }

  /** AD登录 */
  loginByAd(
    idpInstanceId: string,
    params: {
      account_id: string;
      credential: string;
      captchaVerification?: TCaptchaVerification;
      isTest?: boolean;
    },
  ) {
    return this.axiosInstance.post(`${this.prefixURL}/ldap/${idpInstanceId}`, params);
  }

  /**
   * 获得租户初始配置数据
   */
  getSettings(params: { accountId: string }): Promise<any> {
    return this.axiosInstance.get('/v1/authn/settings', { params, skipShowError: true });
  }

  /**
   * 获取开通的应用列表
   */
  getSSOApps(): Promise<any> {
    return this.axiosInstance.get('/v1/authn/apps', { skipShowError: true });
  }

  loginByEmailOrDomain(params: {
    email?: string;
    domain?: string;
    accountId?: string;
    domainName?: string;
  }): Promise<any> {
    return this.axiosInstance.get('/v1/authn/sso', { params, skipShowError: true });
  }
}
export default new AuthnApi();
