// 数据上报SDK文档 https://iwiki.woa.com/p/490519070
import { dataReport as baseDataReport, dataReportAsync } from '@tencent/oneid-utils';
import JSRuntime from 'common/utils/JSRuntime';
import wemeetBeaconReport from './wemeet';

const BASE_DATA_REPORT_PREFIX = 'e#oneid_';

export enum APP_REPORT_KEY {
  WEMEET,
}

/**
 * 多应用 & oneid数据上报
 * @param eventName 上报事件名称
 * @param globalParams 上报公参
 * @param params 上报私参
 * @returns
 */
export const dataReport = (
  event: string,
  globalParams?: Record<string, any>,
  params?: Record<string, any>,
  options?: {
    [APP_REPORT_KEY.WEMEET]?: boolean;
    isSendBeacon?: boolean;
  },
) => {
  // NOTICE 如果是私有化，不上报
  if (JSRuntime.isPrivate || !event) return;
  if (event.startsWith(BASE_DATA_REPORT_PREFIX)) {
    if (options?.isSendBeacon)
      return dataReportAsync({ event, params: { ...globalParams, ...params } });
    return baseDataReport({ event, params: { ...globalParams, ...params } });
  }

  if (event.startsWith('e#')) {
    const newEventName = event.replace('e#', BASE_DATA_REPORT_PREFIX);
    if (options?.isSendBeacon)
      dataReportAsync({ event: newEventName, params: { ...globalParams, ...params } });
    else baseDataReport({ event: newEventName, params: { ...globalParams, ...params } });
  }

  // 如果设置了meeting为false，则代表不上传，其他的都上传
  // 目前只有idp配置了APP_REPORT_KEY.WEMEET，其他服务中的e#都向会议上报
  if (_.isBoolean(options?.[APP_REPORT_KEY.WEMEET]) && !options?.[APP_REPORT_KEY.WEMEET]) {
    return;
  }
  // 公参私参在向会议上报是有另一套处理逻辑，并没有直接合并，因此此处分开为两个参数
  wemeetBeaconReport(event, globalParams, params);
};
