import { TreeOptionData } from 'tdesign-react/es/cascader/interface';
// import { AccountApi as CommonAccountApi } from 'common/api';
import CommonAccountApi from 'common/http/services/AccountApi';
import JSRuntime from 'common/utils/JSRuntime';
import {
  AUTH_STATUS,
  AUTH_TYPE,
  IFaceAuthPolling,
  IOcrSingleInfo,
  SMS_CODE_TYPE,
} from 'idpBase/Constants';
import { AccountApi } from 'idpBase/http';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { FACE_AUTH_TYPE } from './constant';
import { AUTH_METHOD, jobList, talentList, TCoreMaterial, TTipConfig } from '../constants/personal';

class InformationStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<InformationStore>;
  public resetStore: () => void;

  public authInfo = {};

  public isFaceAuthLoading = false;
  public faceAuthInfo = {};

  public isFaceAuthFail = false;

  public isSaveLoading = false;

  // 人脸识别结果
  public faceCheckResult = false;

  public certTypes: TreeOptionData[] = [];

  // 头像上传中
  /* 头像上传需要时间，保证上传完成后再提交*/
  public isAvatarUploading = false;

  // 信息填写页的联动信息
  public tipInfo: TTipConfig;
  /** 职业认证需要进行认证身份类型选择 */
  public tipConfig: TTipConfig[];
  // 未认证用户的job_certify_id为空，首次保存草稿数据是返回的job_certify_id将作为用户的认证id并不变
  // 因此需要再获取草稿信息是进行保存，并在保存草稿数据时进行更新
  public job_certify_id = '';
  /** 图片材料 */
  public materialMap: Map<AUTH_METHOD, { core_materials: TCoreMaterial; external_link: string }> =
    new Map();

  // 微信OCR信息中手机号是否和会议登录手机号一致
  public isLoginPhone = false;
  // 微信OCR信息
  public wechatOcrInfo: IOcrSingleInfo[] = [];

  public cancelAuthResultPolling: () => void;

  // 是否要跳转到结果页
  public get shouldGotoResult() {
    const status = m.get(this.authInfo, 'certify_base.status');
    return typeof status === 'number' && status !== AUTH_STATUS.INIT;
  }

  /**
   * 获取认证信息
   */
  public async getAuthInfo(authType: AUTH_TYPE) {
    const authInfo = await AccountApi.getAuthInfo(authType);
    if (!m.get(authInfo, 'errCode')) {
      this.updateStore({
        job_certify_id: m.get(authInfo, 'certify_base.job_certify_id'),
        authInfo,
        isLoginPhone: m.get(authInfo, 'certify_user_info.material_mobile_same_login'),
        wechatOcrInfo: m.get(authInfo, 'certify_materials.core_materials.0.url_list.0.ocr_info'),
      });
      // 保存材料信息
      this.materialMap.set(m.get(authInfo, 'certify_materials.material_resource_type'), {
        core_materials: m.get(authInfo, 'certify_materials.core_materials'),
        external_link: m.get(authInfo, 'certify_materials.external_link'),
      });
      return authInfo;
    }
    return;
  }

  /**
   * 发送手机验证码
   * @param phone 手机号
   * @param event 验证码对应的事件
   * @returns {Promise}
   */
  public async getSmsCode(sms_code_type: SMS_CODE_TYPE, certify_type: AUTH_TYPE) {
    const res = await AccountApi.sendSmsCode(sms_code_type, certify_type, this.job_certify_id);
    if (!m.get(res, 'errCode')) {
      return res;
    }
    return;
  }

  /**
   * 保存验证信息
   * @param params
   * @returns
   */
  public async save(params: Record<string, any>) {
    this.isSaveLoading = true;
    const res = await AccountApi.saveAuthInfo(params);
    this.updateStore({
      isSaveLoading: false,
    });
    if (!m.get(res, 'errCode')) {
      this.updateStore({
        job_certify_id: res.job_certify_id,
      });
      return true;
    }
    return false;
  }

  /**
   * 图片上传，分三步
   * 1. 获取token
   * 2. 图片带上token上传到cos桶
   * 3. 获取图片的临时链接
   */
  public async uploadImg(
    img: File,
    upload_resource: string,
  ): Promise<{ url: string; cos_id: string; error: string } | undefined> {
    const ret = await CommonAccountApi.uploadImg({
      job_certify_id: this.job_certify_id,
      upload_resource,
      img,
    });
    if (ret) return { ...ret, error: '' };
    return;
  }

  public async getSafeCheckUrl(params: { url: string }) {
    const ret = await AccountApi.sendForLogoSafe(params);
    if (!m.get(ret, 'errCode')) {
      return ret.url;
    }
    return;
  }

  // 获取职业信息
  getCareerInfo(authType: AUTH_TYPE) {
    switch (authType) {
      case AUTH_TYPE.OCCUPATION:
        return jobList;
      case AUTH_TYPE.KOL:
        return talentList;
      default:
        return [];
    }
  }

  public async getCertTypes(authType: AUTH_TYPE) {
    const dataList = this.getCareerInfo(authType);
    function loopMap(arr: TreeOptionData[]): TreeOptionData[] {
      return (
        arr.map?.((item: TreeOptionData) => ({
          ...item,
          value: item.name,
          label: item.name,
          children: _.isArray(item.children) ? loopMap(item.children) : [],
        })) || []
      );
    }
    this.updateStore({
      certTypes: loopMap(dataList),
    });
  }

  /** OCR */
  public async getOcrInfo(cosId: string) {
    const ocrRes = await AccountApi.getOcr(cosId);
    if (m.get(ocrRes, 'errCode')) {
      return;
    }
    this.updateStore({
      isLoginPhone: ocrRes.is_login_phone,
      wechatOcrInfo: ocrRes.ocr_info,
    });
    return ocrRes.ocr_info;
  }

  /**
   * 获取人脸识别token和url信息
   * @param faceType 0 人脸识别，1身份核实
   * @returns
   */
  public async getFaceAuthInfo(faceType: FACE_AUTH_TYPE) {
    this.updateStore({
      isFaceAuthLoading: true,
      isFaceAuthFail: false,
    });
    const userInfo = this.global?.rootStore?.userInfo;
    const res = await AccountApi.getFaceAuthInfo({
      redirect_url: `${JSRuntime.originalIdpSite}/oneid-auth/face-result`,
      business_type: faceType,
      access_source: 'web',
      job_certify_id: this.job_certify_id,
      account_id: m.get(userInfo, 'account.accountId'),
    });
    this.updateStore({
      isFaceAuthLoading: false,
    });
    if (!m.get(res, 'errCode')) {
      this.updateStore({
        faceAuthInfo: res,
      });
      this.pollingFaceAuthResult(faceType, res.biz_token);
    }
    return;
  }

  /** 获取人脸认证结果 */
  public async getFaceAuthResult(businessType: FACE_AUTH_TYPE, bizToken: string) {
    const res = await AccountApi.getFaceCheckResult({
      biz_token: bizToken,
      business_type: businessType,
    });
    if (!m.get(res, 'errCode')) {
      if (
        businessType === FACE_AUTH_TYPE.FACE_AUTH
          ? m.get(res, 'is_certify')
          : m.get(res, 'is_face_auth')
      ) {
        this.updateStore({
          faceCheckResult: true,
        });
        return true;
      }
    }
    return false;
  }

  /**
   * 轮训获取人脸识别结果
   * @param businessType 识别类型 0 实名认证，1身份核实
   * @param bizToken url对应的token
   * @returns 返回是否成功
   */
  async pollingFaceAuthResult(businessType: FACE_AUTH_TYPE, bizToken: string) {
    const pollingCallback = await AccountApi.getFaceCheckResult(
      {
        biz_token: bizToken,
        business_type: businessType,
      },
      {
        pollingConfig: {
          pollingWait: 1000,
          onSuccess: (res: IFaceAuthPolling) => {
            const isCertify = m.get(res, 'is_certify');
            const isFaceAuth = m.get(res, 'is_face_auth');
            const authSuccess = businessType === FACE_AUTH_TYPE.FACE_AUTH ? isCertify : isFaceAuth;
            if (authSuccess) {
              // 成功
              this.updateStore({
                faceCheckResult: true,
              });
              return true;
            }
            return false;
          },
          onError: () => {
            // 接口忽略了一些手机人脸验证时的报错情况，确保手机可以进行重新核验
            // 如果接口报错，则中止轮训
            this.updateStore({ isFaceAuthFail: true });
            return true;
          },
          onEnd: () => {
            this.updateStore({ isFaceAuthFail: true });
          },
          // 10分钟轮训的次数
          maxPollingCount: 600,
        },
      },
    );
    this.cancelAuthResultPolling = pollingCallback?.cancelPolling;
  }
}

export default InformationStore;
