import loading from 'common/static/images/loading.gif';
import metLoadingImg from 'common/static/images/metLoading.gif';
import noSearchResultImg from 'common/static/images/noSearchResult.png';
import newNotFoundImg from 'common/static/images/notFoundNew.png';
import Utils from 'common/utils';
import ErrorBlankPage from './ErrorBlankPage';
import Button from '../Button';
import Icon from '../Icon';
import ImageApply from '../ImageApply';
import BlankScss from './BlankPage.module.scss';

// 默认状态，常用于Table、卡片页初始空状态
interface INoData {
  type: 'NO_DATA';
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  iconType?: string; // icon 类型
  learnMoreLink?: string; // “了解更多”跳转链接
  mode?: 'default' | 'card'; // card: 500px高，全局统一样式
}

// 筛选条件下无数据时使用，常用于Table和卡片页
interface IFilterNoData {
  type: 'FILTER_NO_DATA';
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  mode?: 'default' | 'card'; // card: 500px高，全局统一样式
}

interface ILoading {
  type: 'LOADING' | 'PART_LOADING';
  className?: string;
  style?: React.CSSProperties;
  fullHeight?: boolean; // 整屏高度（100vh - header 高度）
  size?: 'default' | 'small'; // loading圈圈的大小
  mode?: 'default' | 'card'; // card: 500px高，全局统一样式
}

interface IMetLoading {
  type: 'MET_LOADING';
  style?: React.CSSProperties;
  className?: string;
  layout?: 'horizontal' | 'vertical';
  fullHeight?: boolean;
  text?: string;
}

// 初始未开通页面，常用于认证源、数据源等页面
interface IGuide {
  type: 'GUIDE';
  className?: string;
  style?: React.CSSProperties;
  img: string;
  title: React.ReactNode;
  desc: React.ReactNode;
  onStart: () => void;
  btnText?: string;
}

interface INotFound {
  type: 'NOT_FOUND';
  className?: string;
  style?: React.CSSProperties;
}

interface INoContactPermission {
  type: 'NO_CONTACT_PERMISSION';
  className?: string;
  style?: React.CSSProperties;
}

interface INoSearchResult {
  type: 'NO_SEARCH_RESULT';
  className?: string;
  style?: React.CSSProperties;
  content?: string;
}

type Props =
  | INoData
  | IFilterNoData
  | ILoading
  | IGuide
  | INotFound
  | IMetLoading
  | INoContactPermission
  | INoSearchResult;

const COMMON_CLS = Utils.uniteClass('eid-blank-page', BlankScss.blankPage);
const FULL_HEIGHT_CLS = Utils.uniteClass('eid-blank-page-full-height', BlankScss.fullHeight);

const BlankPage: React.FC<Props> = ({ className, style, type, ...props }: Props) => {
  switch (type) {
    case 'MET_LOADING': {
      const metProps = props as IMetLoading;
      return (
        <div
          className={Utils.uniteClass(
            COMMON_CLS,
            metProps.fullHeight && FULL_HEIGHT_CLS,
            metProps.layout === 'horizontal' && BlankScss.metLoadingHorizontal,
          )}
        >
          <img src={metLoadingImg} className={BlankScss.metLoadingImg} />
          <div className={BlankScss.metLoadingText}>{metProps.text ?? '正在加载'}</div>
        </div>
      );
    }
    // 页面loading
    case 'LOADING':
      return (
        <div
          className={Utils.uniteClass(
            COMMON_CLS,
            (props as ILoading)?.fullHeight ? FULL_HEIGHT_CLS : '',
            BlankScss.loading,
            (props as ILoading)?.size === 'small' ? BlankScss.smallLoading : '',
            (props as ILoading)?.mode === 'card'
              ? Utils.uniteClass('eid-card', BlankScss.card)
              : '',
            className,
          )}
          style={style}
        >
          <img className={BlankScss.loadingImg} src={loading} alt="加载中..." />
        </div>
      );
    // 局部loading
    case 'PART_LOADING':
      return (
        <div
          className={Utils.uniteClass(
            COMMON_CLS,
            (props as ILoading)?.fullHeight ? FULL_HEIGHT_CLS : '',
            (props as ILoading)?.mode === 'card'
              ? Utils.uniteClass('eid-card', BlankScss.card)
              : '',
            className,
          )}
          style={style}
        >
          <img className={BlankScss.partLoadingImg} src={metLoadingImg} alt="加载中..." />
        </div>
      );

    // 筛选条件下 空状态
    case 'FILTER_NO_DATA':
      return (
        <div
          className={Utils.uniteClass(
            COMMON_CLS,
            (props as IFilterNoData)?.mode === 'card'
              ? Utils.uniteClass('eid-card', BlankScss.card)
              : '',
            className,
          )}
          style={style}
        >
          <div className={Utils.uniteClass('eid-text-2', BlankScss.contentWrapper)}>
            {(props as IFilterNoData)?.content || '暂无符合条件的内容，请尝试修改查找条件'}
          </div>
        </div>
      );
    case 'NO_SEARCH_RESULT':
      return (
        <div className={Utils.uniteClass(className, BlankScss.noSearchResult, 'no-search-result')}>
          <img className={BlankScss.noResultImg} src={noSearchResultImg} />
          <span className={Utils.uniteClass(BlankScss.noResultDesc, 'no-search-result-desc')}>
            {(props as INoSearchResult)?.content || '未找到相关内容'}
          </span>
        </div>
      );

    // 初次开通
    case 'GUIDE':
      return (
        <div
          className={Utils.uniteClass(COMMON_CLS, 'eid-card', BlankScss.guide, className)}
          style={style}
        >
          <ImageApply src={(props as IGuide)?.img} />
          <div className="eid-title">{(props as IGuide)?.title || ''}</div>
          <div className={Utils.uniteClass('eid-text-2', BlankScss.guideDesc)}>
            {(props as IGuide)?.desc || ''}
          </div>
          <Button onClick={(props as IGuide)?.onStart}>
            {(props as IGuide)?.btnText || '开始使用'}
          </Button>
        </div>
      );

    // 404
    case 'NOT_FOUND':
      return (
        <ErrorBlankPage
          style={style}
          className={className}
          img={newNotFoundImg}
          errorCode="404"
          title="页面未找到"
          desc="您寻找的页面不存在，或者已被移动至别的地方"
        />
      );
    case 'NO_CONTACT_PERMISSION':
      return (
        <div className={Utils.uniteClass(BlankScss.noPermission, className)}>
          无访问通讯录权限，请联系管理员
        </div>
      );
    // 初始空状态
    case 'NO_DATA':
    default:
      return (
        <div
          className={Utils.uniteClass(
            COMMON_CLS,
            BlankScss.noData,
            (props as INoData)?.mode === 'card' ? Utils.uniteClass('eid-card', BlankScss.card) : '',
            className,
          )}
          style={style}
        >
          {(props as INoData)?.iconType ? (
            <Icon
              className={BlankScss.noDataIcon}
              type={(props as INoData)?.iconType as string}
              size={64}
              svg
            />
          ) : null}
          <div className={Utils.uniteClass('eid-text-2', BlankScss.contentWrapper)}>
            {(props as INoData)?.content || '当前暂无数据'}
          </div>
          {(props as INoData)?.learnMoreLink ? (
            <a
              className={Utils.uniteClass('eid-text-2', BlankScss.learnMore)}
              href={(props as INoData)?.learnMoreLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              了解更多
            </a>
          ) : null}
        </div>
      );
  }
};

export { ErrorBlankPage };

export default React.memo(BlankPage);
