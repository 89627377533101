import type { AxiosInstancePlus } from '@tencent/oneid-request';
import type { IResponseType } from 'common/constants/Types';
import { http } from 'common/http';
import type {
  IConsentAccreditProps,
  IPersonAuthorizationProps,
  IPersonAuthorizationResponse,
} from '../type';

class PersonApi {
  public axiosInstance: AxiosInstancePlus = http;

  /**
   * 获取个人授权信息
   */
  getPersonAuthorization(
    params: IPersonAuthorizationProps,
  ): Promise<IResponseType<IPersonAuthorizationResponse>> {
    return this.axiosInstance.get('/v1/person/authorization', { params, skipShowError: true });
  }

  /**
   * 同意授权
   */
  consentAccredit(data: IConsentAccreditProps): Promise<IResponseType> {
    return this.axiosInstance.post('/v1/person/consent', data);
  }

  /**
   * 获取授权信息，发送给三方应用
   */
  getAuthorize(params: IConsentAccreditProps): Promise<IResponseType> {
    return this.axiosInstance.get('/v1/person/authorize', { params });
  }
}
export default new PersonApi();
