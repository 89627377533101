import { getBlockedErrMsg } from 'common/components/BlockedErrMsg';
import { AUTH_STATUS as ENT_AUTH_STATUS } from '../modules/auth-enterprise/constants/Constants';

export enum EMAIL_VERIFY_CODE_SCENE {
  EMAIL_LOGIN = 'email_login',
  PASSWORD_FORGOT = 'password_forgot',
}

export enum ENTERPRISE_AUTH_TYPE {
  ENTERPRISE_AUTH = 'enterprise',
  ORG_AUTH = 'organization',
}
// 这是ENTERPRISE_AUTH_TYPE.ENTERPRISE_AUTH的子类型
export enum ENTERPRISE_CERT_TYPES {
  ELECTRICAL_BUSINESS_LICENSE = 1,
  ENTERPRISE_AUTHORIZATION_LETTER = 2,
  TENCENT_CLOUD = 4,
}
// 这是ENTERPRISE_AUTH_TYPE.ORG_AUTH的子类型
export enum ORG_CERT_TYPES {
  ORG_AUTHORIZATION_LETTER = 3,
}

export enum CREATE_CARD_TYPE {
  CREATE_PERSONAL_ACCOUNT,
  CREATE_ORG_ACCOUNT,
  LOGIN_ACCOUNT,
}

// 账号被封禁时错误码
export const BLOCKED_ERROR_CODE = 'E0010344';

export const BLOCKED_ERROR_MSG = {
  [BLOCKED_ERROR_CODE]: getBlockedErrMsg(),
};

// export const MAINLAND_PHONE_REGEXP = /^\+86\s/; // 匹配以'+86 '开头的手机号

export enum PASSWORD_LOGIN_TYPE {
  EMAIL = 'email',
  MOBILE = 'mobile',
}

/** 认证类型，企业认证与个人认证 */
export enum AUTH_TYPE_ENUM {
  /** 个人认证 */
  PERSONAL = 'personal',
  /** 企业认证 */
  ENTERPRISE = 'enterprise',
}

/** 认证类型，区分企业认证个人认证，与接入应用的传参进行对应 */
export enum AUTH_TYPE {
  /** 是否认证过 */
  UNKNOWN = 0,
  /** 达人 */
  KOL = 1,
  /** 职业 */
  OCCUPATION = 2,
  // /** 企业 */
  // ENTPRISE = 3,
  // /** 组织 */
  // ORGANIZATION = 4,
}
/** 企业认证的具体认证类型 */
export enum ENT_AUTH_TYPE {
  /** 未知认证方式 */
  UNKNOWN = 0,
  /** 电子营业执照 */
  LICENSE = 1,
  /** 企业授权函 */
  ENTERPRISE = 2,
  /** 组织机构授权函 */
  ORGANIZATION = 3,
  /** 腾讯云认证 */
  TENCENT = 4,
}

/** 保存类型，企业认证中使用 */
export enum SAVE_TYPE {
  /** 草稿 */
  DRAFT = 1,
  /** 提交审核 */
  APPLY = 2,
}

export const RESET_PWD_ERROR_CODE = ['E0010381', 'E0010382', 'E0010383', 'E0010384', 'E0010451'];

/**
 * 企业微信名片ocr字段
 * mobile/name/account/profession/email
 */
export const enum WECHAT_OCR_FIELD {
  /** 手机号 */
  MOBILE = 'mobile',
  /** 姓名 */
  NAME = 'name',
  /** 企业 */
  ACCOUNT = 'account',
  /** 职位 */
  PROFESSION = 'profession',
  /** 邮箱 */
  EMAIL = 'email',
}

/**
 * 企业微信名片ocr字段对应名称
 * mobile/name/account/profession/email
 */
export const WECHAT_OCR_FIELD_NAME = {
  /** 手机号 */
  [WECHAT_OCR_FIELD.MOBILE]: '手机',
  /** 姓名 */
  [WECHAT_OCR_FIELD.NAME]: '姓名',
  /** 企业 */
  [WECHAT_OCR_FIELD.ACCOUNT]: '企业',
  /** 职位 */
  [WECHAT_OCR_FIELD.PROFESSION]: '职位',
  /** 邮箱 */
  [WECHAT_OCR_FIELD.EMAIL]: '邮箱',
};

/** 单个OCR信息 */
export interface IOcrSingleInfo {
  /** 解析数据名称 */
  key: WECHAT_OCR_FIELD;
  /** 解析数据内容 */
  value: string;
}

/** OCR接口返回 */
export interface IImgOcrInfo {
  /** OCR信息 */
  ocr_info: IOcrSingleInfo[];
  /** 识别mobile是否与会议登陆号码一致 */
  is_login_phone: boolean;
}

export interface IFaceAuthInfo {
  biz_token: string;
  request_id: string;
  url: string;
}

export interface IFaceAuthPolling {
  is_certify: boolean;
  is_face_auth: boolean;
  name: string;
  request_id: string;
}

export const enum SMS_CODE_TYPE {
  UNKNOWN = 0,
  LOGIN_MOBILE = 1,
  MATERIAL_MOBILE = 2,
  MATERIAL_EMAIL = 3,
}
/** 通用认证状态（个人认证定义） */
export enum AUTH_STATUS {
  INIT = 0,
  /** 认证中 */
  REVIEW = 1,
  /** 认证成功 */
  SUCCESS = 2,
  /** 认证失败 */
  FAIL = 3,
  /** 撤回状态 */
  REVOCATION = 4,
  /** 认证过期 */
  EXPIRE = 5,
  /** 认证失效 */
  DISABLED = 6, // 认证失效,例如举报失效
}

/** 企业认证状态与通用认证状态对应 */
export const AUTH_STATUS_NOMARL = {
  /** */
  [ENT_AUTH_STATUS.INITIAL]: AUTH_STATUS.INIT,
  /** 认证成功 */
  [ENT_AUTH_STATUS.SUCCESS]: AUTH_STATUS.SUCCESS,
  /** 认证失败 */
  [ENT_AUTH_STATUS.FAIL]: AUTH_STATUS.FAIL,
  /** 认证过期 */
  [ENT_AUTH_STATUS.EXPIRED]: AUTH_STATUS.EXPIRE,
  /** 认证失效 */
  [ENT_AUTH_STATUS.INVALID]: AUTH_STATUS.DISABLED,
  /** 认证中 */
  [ENT_AUTH_STATUS.INPROGRESS]: AUTH_STATUS.REVIEW,
};
