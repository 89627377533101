import { AccountApi } from 'idpBase/http';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';

class AuthorizationStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<AuthorizationStore>;
  public resetStore: () => void;

  public isAuthorized = false;
  public authInfo = {};

  public authorize() {
    return AccountApi.authorize();
  }

  public syncAuthInfo() {
    return AccountApi.syncAuthInfo();
  }
}
export default AuthorizationStore;
