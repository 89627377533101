import { observer } from 'mobx-react';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import JSRuntime from 'common/utils/JSRuntime';
import { AuthnApi } from 'idpBase/http';

/**
 * 打通端上登陆态
 * @param PageComponent 页面组件
 */
const withClientLogin = (PageComponent: FC) => {
  return observer((props: any) => {
    const initClientAuth = async () => {
      const searchObj = JSRuntime.parseLocationSearch();
      const res = await AuthnApi.getAuthCodeSession({
        code: searchObj.code,
        client: searchObj.client,
      });
      if (!m.get(res, 'errCode')) {
        return true;
      }
      return false;
    };

    const [state, doAsync] = useAsyncFn(initClientAuth, []);

    useEffect(() => {
      doAsync();
    }, []);

    if (!state.value) return;

    return <PageComponent {...props} />;
  });
};

export default withClientLogin;
