import { AxiosInstancePlus } from '@tencent/oneid-request';
import { IMG_SAFE_CHECK_TYPE, UPLOAD_IMG_KEY } from 'common/constants/Constants';
import { IResponseType } from 'common/constants/Types';
import { http } from 'common/http';

class AccountApi {
  public axiosInstance: AxiosInstancePlus = http;

  public prefixURL = '/person/v1';
  /**
   * 获取上传图片token
   * @param
   * @returns
   */
  getUploadToken(params: {
    job_certify_id?: string;
    upload_resource: string;
    resource_type: string;
    safe_check_type?: IMG_SAFE_CHECK_TYPE;
  }): Promise<{ cos_url: string; put_auth: string; cos_id: string }> {
    return this.axiosInstance.post(
      `${this.prefixURL}/account/personal/certify/upload_auth`,
      params,
      { autoAbort: false },
    );
  }

  /**
   * 上传图片到cos桶
   * @param
   * @returns
   */
  putImgToCos(params: { cos_url: string; cos_auth: string; img: File }): Promise<IResponseType> {
    return this.axiosInstance.request({
      baseURL: params.cos_url,
      method: 'put',
      headers: {
        'Content-Type': params.img.type,
        // 'Content-Length': params.img.size.toString(),
        Authorization: params.cos_auth,
      },
      data: params.img,
      autoAbort: false,
    });
  }

  /**
   * 获取图片临时链接
   * @param
   * @returns
   */
  getTemporaryLink(params: {
    cos_id: string;
    job_certify_id?: string;
    upload_resource: string;
    safe_check_type?: IMG_SAFE_CHECK_TYPE;
  }): Promise<{ download_url: string }> {
    return this.axiosInstance.post(
      `${this.prefixURL}/account/personal/certify/download_url`,
      params,
      { autoAbort: false },
    );
  }

  /**
   * 整合图片上传流程，分三步
   * 1. 获取token
   * 2. 图片带上token上传到cos桶
   * 3. 获取图片的临时链接
   *
   * 拆三步的原因：上传cos桶因为要传文件 对后台压力很大 所以拆成三个，前端直接传cos桶就不用走后台那边了 @richardchli(李超)
   */
  async uploadImg({
    job_certify_id,
    upload_resource,
    img,
    safe_check_type,
  }: {
    job_certify_id?: string;
    upload_resource: string;
    img: File;
    safe_check_type?: IMG_SAFE_CHECK_TYPE; // 默认为 IMG_SAFE_CHECK_TYPE.PERSON 个人认证审核
  }): Promise<undefined | { url: string; cos_id: string }> {
    const resourceType = `${img.name.match(/\.[a-zA-Z]+$/)?.[0]}`;
    if (!UPLOAD_IMG_KEY.has(upload_resource)) {
      // const err = '错误的form name 上传图片需要唯一的form name';
      return;
    }
    const cosRes = await this.getUploadToken({
      job_certify_id,
      upload_resource,
      resource_type: resourceType,
      safe_check_type,
    });
    if (m.get(cosRes, 'errCode')) {
      return;
    }
    const { cos_url: cosUrl, put_auth: putAuth, cos_id: cosId } = cosRes;
    const putCosRet = await this.putImgToCos({
      cos_url: cosUrl,
      cos_auth: putAuth,
      img,
    });
    if (m.get(putCosRet, 'errCode')) {
      return;
    }
    const downloadUrlRet = await this.getTemporaryLink({
      cos_id: cosId,
      job_certify_id,
      upload_resource,
      safe_check_type,
    });
    if (m.get(downloadUrlRet, 'errCode')) {
      return;
    }
    return {
      url: downloadUrlRet.download_url,
      cos_id: cosId,
    };
  }
}

export default new AccountApi();
