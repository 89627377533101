import BaseApi from 'common/apiRequest/apis/BaseApi';
import JSRuntime from 'common/utils/JSRuntime';
import { Request } from '../apiConcentrator/Person';
import APINames from '../APINames';

class AccountsApi extends BaseApi {
  public requestInstance = new Request(APINames.ACCOUNT);
  public accountInstance = new Request(APINames.ACCOUNT, {
    baseURL: `${JSRuntime.oauth2Site}/v1`,
  });

  /**
   * 进行头像的安全审核
   * @param params
   * @returns
   */
  sendForLogoSafe(params: { url: string }) {
    return this.requestInstance.post(['personal', 'certify', 'deal_file_safe'], params);
  }

  /**
   * 图片上传
   * @param file
   * @returns
   */
  uploadFile(file: FormData) {
    return this.sendFormData({
      url: ['personal', 'certify', 'personal_account_job_certify_info_upload'],
      data: file,
    });
  }

  getAppMeta(accountId: string, clientId: string) {
    return this.accountInstance.get([accountId, '.well-known', 'oauth-client', clientId]);
  }

  checkAuthorization(accountId: string, params: { redirect_url: string; consent: boolean }) {
    const form = document.createElement('form');
    const formData = new FormData();
    formData.append('redirect_url', params.redirect_url);
    formData.append('consent', params.consent.toString());
    form.innerHTML = `
    <input name="redirect_url" value="${params.redirect_url}"/>
    <input name="consent" value="${params.consent}"/>`;
    form.action = `${JSRuntime.oauth2Site}/v1/account/${accountId}/consent`;
    form.method = 'POST';
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
}
export default new AccountsApi();
