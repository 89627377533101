import { AUTH_TYPE } from 'idpBase/Constants';
import { AccountApi } from 'idpBase/http';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';

class ProgressStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<ProgressStore>;
  public resetStore: () => void;

  public isBlockLoading = true;
  public authInfo = {};

  /**
   * 获取认证信息
   */
  public async getAuthInfo(authType: AUTH_TYPE) {
    const res = await AccountApi.getAuthInfo(authType);
    this.updateStore({
      isBlockLoading: false,
    });
    if (m.get(res, 'errCode')) return;
    this.updateStore({
      authInfo: res,
    });
  }

  /** 撤回认证 */
  public revokeAuth(id: string) {
    return AccountApi.revokeAuth(id);
  }
}
export default ProgressStore;
