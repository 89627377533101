import { AUTH_TYPE, IOcrSingleInfo } from 'idpBase/Constants';

// 认证主材料的类型
export type TMaterialType = {
  name: string;
  desc: string;
};

/** 个人认证-认证身份类型 */
export enum AUTH_IDENTITY_TYPE {
  /** 未知类型 */
  JOB_TITLE_TYPE_UNKNOWN = 0,
  /** 企业和职位 */
  ACCOUNT_AND_JOB = 1,
  /** 职位 */
  ONLY_JOB = 2,
  /** 企业和职称 */
  ACCOUNT_AND_PROFESSION = 3,
  /** 职称 */
  ONLY_PROFESSION = 4,
  /** 达人认证类型 */
  EXPERT = 5,
  /** 赛事 */
  EVENT = 6,
}

/** 个人认证-认证身份类型名称 */
export const AUTH_IDENTITY_NAME = {
  /** 企业和职位 */
  [AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB]: '企业和职位',
  /** 职位 */
  [AUTH_IDENTITY_TYPE.ONLY_JOB]: '职位',
  /** 企业和职称 */
  [AUTH_IDENTITY_TYPE.ACCOUNT_AND_PROFESSION]: '企业和职称',
  /** 职称 */
  [AUTH_IDENTITY_TYPE.ONLY_PROFESSION]: '职称',
  /** 达人 */
  [AUTH_IDENTITY_TYPE.EXPERT]: '达人',
  /** 赛事 */
  [AUTH_IDENTITY_TYPE.EVENT]: '赛事',
};

/** 个人认证-提示配置 */
export type TTipConfig = {
  type: AUTH_IDENTITY_TYPE;
  tipText: string;
  materialInfo: {
    type: string;
    desc: string[];
  }[];
};

/** 达人类型 */
export enum TALENT_TYPE {
  /** 非达人类型 */
  NOT_TALENT = 0,
  /** 普通达人 */
  NORMAL_TALENT = 1,
  /** 赛事达人 */
  ENVENT_TALENT = 2,
}

/** 达人认证类型的映射表 */
export const IDENTITY_TALENT_TYPE = {
  /** 达人认证身份对应普通达人类型 */
  [AUTH_IDENTITY_TYPE.EXPERT]: TALENT_TYPE.NORMAL_TALENT,
  /** 赛事认证身份对应赛事选手类型 */
  [AUTH_IDENTITY_TYPE.EVENT]: TALENT_TYPE.ENVENT_TALENT,
};

/** 个人认证- */
export type TPersonalAuth = {
  name: string;
  id?: string;
  children?: TPersonalAuth[];
  tipConfig?: TTipConfig[];
};

export const jobList: TPersonalAuth[] = [
  {
    name: '管理',
    children: [
      {
        name: 'CEO',
        id: '0',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“CEO”',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和公司营业执照',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和事业单位法人证明',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和公司营业执照',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和事业单位法人证明',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如：XX公司CEO',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和公司营业执照',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和事业单位法人证明',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和公司营业执照',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和事业单位法人证明',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'CTO',
        id: '1',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“CTO”',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和公司营业执照',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和事业单位法人证明',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和公司营业执照',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和事业单位法人证明',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如：XX公司CTO',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和公司营业执照',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和事业单位法人证明',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和公司营业执照',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和事业单位法人证明',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '总监',
        id: '2',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“总监”',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和公司营业执照',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和事业单位法人证明',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和公司营业执照',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和事业单位法人证明',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如：XX公司总监',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和公司营业执照',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和事业单位法人证明',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和公司营业执照',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和事业单位法人证明',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '总经理',
        id: '3',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“总经理”',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和公司营业执照',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和事业单位法人证明',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和公司营业执照',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和事业单位法人证明',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如：XX公司总经理',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和公司营业执照',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '在职证明和事业单位法人证明',
                desc: [
                  '1、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和公司营业执照',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内公司营业执照，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '劳务合同和事业单位法人证明',
                desc: [
                  '1、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交有效期内事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '法定代表人',
        id: '4',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“法人”',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如：XX公司法人',
            materialInfo: [
              {
                type: '(本人为法人) 公司营业执照/事业单位法人证明',
                desc: [
                  '请提交有效期内公司营业执照或事业单位法人证明，需盖有公章，确保所有信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '人力资源专员',
        id: '5',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“人事”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如：XX公司人事',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'HRBP',
        id: '6',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如：XX公司HRBP',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“HRBP”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等能够证明在职公司、目前职位、在职时效以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: '教育',
    children: [
      {
        name: '教育机构培训人员',
        id: '7',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX教育机构语文老师”',
            materialInfo: [
              {
                type: '在职证明和教师资格证',
                desc: [
                  '1、请提交由具备办学许可证的合法教育培训机构开具的，有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。其中 在职证明 需在 3个月 内开具，劳务合同有效期需大于半年。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '劳务合同和教师资格证',
                desc: [
                  '1、请提交由具备办学许可证的合法教育培训机构开具的，结束时间大于半年的劳务合同，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”、“教师工作证”等能够证明在职学校、职位以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“语文老师”',
            materialInfo: [
              {
                type: '在职证明和教师资格证',
                desc: [
                  '1、请提交由具备办学许可证的合法教育培训机构开具的，有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。其中 在职证明 需在 3个月 内开具，劳务合同有效期需大于半年。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '劳务合同和教师资格证',
                desc: [
                  '1、请提交由具备办学许可证的合法教育培训机构开具的，结束时间大于半年的劳务合同，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”、“教师工作证”等能够证明在职学校、职位以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '中小学教师',
        id: '8',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_PROFESSION,
            tipText: '如“中级教师”',
            materialInfo: [
              {
                type: '职称证明',
                desc: [
                  '请提交由人社或教育部门颁发的教师职称证明，确保姓名、任职资格、任职学校和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，能够证明在职学校、职称以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_PROFESSION,
            tipText: '如“XX小学中级教师”',
            materialInfo: [
              {
                type: '职称证明',
                desc: [
                  '请提交由人社或教育部门颁发的教师职称证明，确保姓名、任职资格、任职学校和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，能够证明在职学校、职称以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“语文老师”',
            materialInfo: [
              {
                type: '在职证明和教师资格证',
                desc: [
                  '1、请提交由公办、民办学校开具的，3个月内开具的在职证明，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '劳务合同和教师资格证',
                desc: [
                  '1、请提交由公办、民办学校开具的，结束时间大于半年的劳务合同，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”、“教师工作证”等能够证明在职学校、职位以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX小学语文老师”',
            materialInfo: [
              {
                type: '在职证明和教师资格证',
                desc: [
                  '1、请提交由公办、民办学校开具的，3个月内开具的在职证明，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '劳务合同和教师资格证',
                desc: [
                  '1、请提交由公办、民办学校开具的，结束时间大于半年的劳务合同，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”、“教师工作证”等能够证明在职学校、职位以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '高等学校教师',
        id: '9',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_PROFESSION,
            tipText: '如“中级教师”',
            materialInfo: [
              {
                type: '职称证明',
                desc: [
                  '请提交由人社或教育部门颁发的教师职称证明，确保姓名、任职资格、任职学校和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于高校官网个人主页链接及截图、“聘书”、“资格证书”等能够认证在职学校、职称以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_PROFESSION,
            tipText: '如“XX高校中级教师”',
            materialInfo: [
              {
                type: '职称证明',
                desc: [
                  '请提交由人社或教育部门颁发的教师职称证明，确保姓名、任职资格、任职学校和 公章 等信息清晰可见、无遮挡。如证明缺少学校等信息，请在补充材料中提交在职证明/劳务合同/教师证等能够证明在职学校等证明材料。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于高校官网个人主页链接及截图、“聘书”、“资格证书”等能够证明在职学校、职称以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“语文老师”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于高校官网个人主页链接及截图、“聘书”、“资格证书”等能够证明在职学校、职称以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX高校语文老师”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于高校官网个人主页链接及截图、“聘书”、“资格证书”等能够证明在职学校、职称以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '中等职业学校教师',
        id: '39',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_PROFESSION,
            tipText: '如“中级教师”',
            materialInfo: [
              {
                type: '职称证明',
                desc: [
                  '请提交由人社或教育部门颁发的教师职称证明，确保姓名、任职资格、任职学校和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，能够证明在职学校、职称以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_PROFESSION,
            tipText: '如“XX职校中级教师”',
            materialInfo: [
              {
                type: '职称证明',
                desc: [
                  '请提交由人社或教育部门颁发的教师职称证明，确保姓名、任职资格、任职学校和 公章 等信息清晰可见、无遮挡。如证明缺少学校等信息，请在补充材料中提交在职证明/劳务合同/教师证等能够证明在职学校等证明材料。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，能够证明在职学校、职称以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“语文老师”',
            materialInfo: [
              {
                type: '在职证明和教师资格证',
                desc: [
                  '1、请提交由公办、民办学校开具的，3个月内开具的在职证明，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '劳务合同和教师资格证',
                desc: [
                  '1、请提交由公办、民办学校开具的，结束时间大于半年的劳务合同，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”、“教师工作证”等能够证明在职学校、职位以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX职校语文老师”',
            materialInfo: [
              {
                type: '在职证明和教师资格证',
                desc: [
                  '1、请提交由公办、民办学校开具的，3个月内开具的在职证明，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '劳务合同和教师资格证',
                desc: [
                  '1、请提交由公办、民办学校开具的，结束时间大于半年的劳务合同，确保姓名、身份证号、学校名称、职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                  '2、请提交由教育部颁发的有效期内教师资格证，需盖有公章，并清晰展示“姓名”、“身份证号”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”、“教师工作证”等能够证明在职学校、职位以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: '医疗健康',
    children: [
      {
        name: '医生',
        id: '10',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX医院内科医生”',
            materialInfo: [
              {
                type: '电子执照和在职证明',
                desc: [
                  `1、请提交医生电子执照：请登录中国医师电子化注册信息系统个人端（https:/cndocsys.cn/home/index）点击“亮牌”，并清晰截图医师执业证书。截图需完整包含所有信息；如您无法提供电子执照，请提供纸质版医生执业证书和卫健委执业查询完整截图，确保个人信息、证书编号、专业及类别清晰可见。`,
                  '2、请提交由医疗机构3个月内开具的，有效期内的在职证明，确保姓名、身份证号、医师职称、所在科室、入职时间和公章等信息清晰可见。',
                  '如无法提供在职证明，请提交就职医院官网截图，清晰展示姓名、单位、个人头像、所处科室和职称等相关信息。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“内科医生”',
            materialInfo: [
              {
                type: '电子执照和在职证明',
                desc: [
                  '1、请提交医生电子执照：请登录中国医师电子化注册信息系统个人端（https:/cndocsys.cn/home/index）点击“亮牌”，并清晰截图医师执业证书。截图需完整包含所有信息；如您无法提供电子执照，请提供纸质版医生执业证书和卫健委执业查询完整截图，确保个人信息、证书编号、专业及类别清晰可见。',
                  '2、请提交由医疗机构3个月内开具的，有效期内的在职证明，确保姓名、身份证号、医师职称、所在科室、入职时间和公章等信息清晰可见。',
                  '如无法提供在职证明，请提交就职医院官网截图，清晰展示姓名、单位、个人头像、所处科室和职称等相关信息。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_PROFESSION,
            tipText: '如“XX医院内科主任”',
            materialInfo: [
              {
                type: '职称证明、电子执照、在职证明',
                desc: [
                  '1、请提交由人社或卫生部门及相关技术资格评审会颁发的医师职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格名称”和“证书编号”等相关信息。',
                  '2、请提交医生电子执照：请登录中国医师电子化注册信息系统个人端（https:/cndocsys.cn/home/index）点击“亮牌”，并清晰截图医师执业证书。截图需完整包含所有信息；如您无法提供电子执照，请提供纸质版医生执业证书和卫健委执业查询完整截图，确保个人信息、证书编号、专业及类别清晰可见。',
                  '3、请提交由医疗机构3个月内开具的，有效期内的在职证明，确保姓名、身份证号、医师职称、所在科室、入职时间和公章等信息清晰可见。',
                  '如无法提供在职证明，请提交就职医院官网截图，清晰展示姓名、单位、个人头像、所处科室和职称等相关信息。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_PROFESSION,
            tipText: '如“内科主任”',
            materialInfo: [
              {
                type: '职称证明、电子执照、在职证明',
                desc: [
                  '1、请提交由人社或卫生部门及相关技术资格评审会颁发的医师职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格名称”和“证书编号”等相关信息。',
                  '2、请提交医生电子执照：请登录中国医师电子化注册信息系统个人端（https:/cndocsys.cn/home/index）点击“亮牌”，并清晰截图医师执业证书。截图需完整包含所有信息；如您无法提供电子执照，请提供纸质版医生执业证书和卫健委执业查询完整截图，确保个人信息、证书编号、专业及类别清晰可见。',
                  '3、请提交由医疗机构3个月内开具的，有效期内的在职证明，确保姓名、身份证号、医师职称、所在科室、入职时间和公章等信息清晰可见。',
                  '如无法提供在职证明，请提交就职医院官网截图，清晰展示姓名、单位、个人头像、所处科室和职称等相关信息。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '药师',
        id: '11',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX医院药师”',
            materialInfo: [
              {
                type: '执业资格证和在职证明',
                desc: [
                  '1、请提交药师职业资格证，确保姓名、身份证号、职务、身份证号、证书编号等相关信息清晰可见。',
                  '2、请提交由医疗机构3个月内开具的，有效期内的在职证明，确保姓名、身份证号、职称、所在科室、入职时间和公章等信息清晰可见。',
                  '如无法提供在职证明，请提交就职医院官网截图，清晰展示姓名、单位、个人头像、所处科室和职称等相关信息。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“药师”',
            materialInfo: [
              {
                type: '执业资格证和在职证明',
                desc: [
                  '1、请提交药师职业资格证，确保姓名、身份证号、职务、身份证号、证书编号等相关信息清晰可见。',
                  '2、请提交由医疗机构3个月内开具的，有效期内的在职证明，确保姓名、身份证号、职称、所在科室、入职时间和公章等信息清晰可见。',
                  '如无法提供在职证明，请提交就职医院官网截图，清晰展示姓名、单位、个人头像、所处科室和职称等相关信息。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_PROFESSION,
            tipText: '如“XX医院主管药师”',
            materialInfo: [
              {
                type: '职称证明、执业资格证、在职证明',
                desc: [
                  '1、请提交由相关医院或技术资格评审会颁发的药师职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格名称”和“证书编号”等相关信息。',
                  '2、请提交药师职业资格证，确保姓名、身份证号、职务、身份证号、证书编号等相关信息清晰可见。',
                  '3、请提交由医疗机构3个月内开具的，有效期内的在职证明，确保姓名、身份证号、职称、所在科室、入职时间和公章等信息清晰可见。',
                  '如无法提供在职证明，请提交就职医院官网截图，清晰展示姓名、单位、个人头像、所处科室和职称等相关信息。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_PROFESSION,
            tipText: '如“主管药师”',
            materialInfo: [
              {
                type: '职称证明、执业资格证、在职证明',
                desc: [
                  '1、请提交由相关医院或技术资格评审会颁发的药师职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格名称”和“证书编号”等相关信息。',
                  '2、请提交药师职业资格证，确保姓名、身份证号、职务、身份证号、证书编号等相关信息清晰可见。',
                  '3、请提交由医疗机构3个月内开具的，有效期内的在职证明，确保姓名、身份证号、职称、所在科室、入职时间和公章等信息清晰可见。',
                  '如无法提供在职证明，请提交就职医院官网截图，清晰展示姓名、单位、个人头像、所处科室和职称等相关信息。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '营养师',
        id: '12',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX医院营养师”',
            materialInfo: [
              {
                type: '注册营养师资格和在职证明',
                desc: [
                  '1、请提交中国营养学会颁发的有效期内的注册营养师/注册营养技师资格，确保清晰、完整展示所有信息。',
                  '2、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '注册营养师资格和劳动合同',
                desc: [
                  '1、请提交中国营养学会颁发的有效期内的注册营养师/注册营养技师资格，确保清晰、完整展示所有信息。',
                  '2、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡',
                ],
              },
              {
                type: '二级以上公共营养师资格和在职证明',
                desc: [
                  '1、请提交人社部颁发的有效期内的二级公共营养师资格证书，确保清晰、完整展示所有信息。',
                  '2、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '二级以上公共营养师资格和劳动合同',
                desc: [
                  '1、请提交人社部颁发的有效期内的二级公共营养师资格证书，确保清晰、完整展示所有信息。',
                  '2、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“营养师”',
            materialInfo: [
              {
                type: '注册营养师资格和在职证明',
                desc: [
                  '1、请提交中国营养学会颁发的有效期内的注册营养师/注册营养技师资格，确保清晰、完整展示所有信息。',
                  '2、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '注册营养师资格和劳动合同',
                desc: [
                  '1、请提交中国营养学会颁发的有效期内的注册营养师/注册营养技师资格，确保清晰、完整展示所有信息。',
                  '2、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡',
                ],
              },
              {
                type: '二级以上公共营养师资格和在职证明',
                desc: [
                  '1、请提交人社部颁发的有效期内的二级公共营养师资格证书，确保清晰、完整展示所有信息。',
                  '2、请提交3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '二级以上公共营养师资格和劳动合同',
                desc: [
                  '1、请提交人社部颁发的有效期内的二级公共营养师资格证书，确保清晰、完整展示所有信息。',
                  '2、请提交结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
        ],
      },
      // {
      //   name: '康复治疗师',
      //   desc: [
      //     '人社部颁发的初级以上职称康复治疗师技师证书。',
      //     '3个月内开具的在职证明 或 有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名。',
      //   ],
      //   id: '13',
      // },
      {
        name: '心理咨询师',
        id: '14',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司心理咨询师”',
            materialInfo: [
              {
                type: '在职证明/劳动合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“心理咨询师”',
            materialInfo: [
              {
                type: '国家二级/三级心理咨询师证',
                desc: [
                  '请提交国家二级/三级心理咨询师证，包括个人照片页与个人信息页，彩色原件，证件信息清晰。',
                ],
              },
              {
                type: '在职证明/劳动合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: '法律',
    children: [
      {
        name: '律师',
        id: '15',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX律所律师”',
            materialInfo: [
              {
                type: '律师执业证',
                desc: [
                  '请提交由相关法务部门颁布的有效期内律师执业证，需盖有公章，并清晰展示“姓名”、“执业机构”、“执业证号”、“年度考核备案”等相关信息。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“律师”',
            materialInfo: [
              {
                type: '律师执业证',
                desc: [
                  '请提交由相关法务部门颁布的有效期内律师执业证，需盖有公章，并清晰展示“姓名”、“执业机构”、“执业证号”、“年度考核备案”等相关信息。',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: '金融/财经',
    children: [
      {
        name: '金融分析师',
        id: '16',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司金融分析师”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(分析师)。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(分析师)。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(分析师)。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(分析师)。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“金融分析师”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(分析师)。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(分析师)。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(分析师)。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(分析师)。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '证券投资经理',
        id: '17',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司证券投资经理”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(投资顾问)。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(投资顾问)。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(投资顾问)。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(投资顾问)。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“证券投资经理”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(投资顾问)。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(投资顾问)。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(投资顾问)。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券投资咨询(投资顾问)。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '证券经纪人',
        id: '18',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司证券经纪人”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券经纪人。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券经纪人。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券经纪人。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券经纪人。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“证券经纪人”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券经纪人。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券经纪人。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券经纪人。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位为证券经纪人。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '基金经理',
        id: '19',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司基金经理”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金经理。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金经理。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金经理。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金经理。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“基金经理”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金经理。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金经理。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金经理。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金经理。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '基金投资经理',
        id: '20',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司基金投资经理”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金投资经理。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金投资经理。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金投资经理。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金投资经理。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“基金投资经理”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金投资经理。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金投资经理。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金投资经理。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。职位需为基金投资经理。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '财经作家',
        id: '21',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“财经作家”',
            materialInfo: [
              {
                type: '作品证明',
                desc: [
                  '作为第一作者，通过主流出版社公开出版的三本以及以上财经类书籍的的图书实体书籍封面页+扉页以及出版证明。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '会计师',
        id: '22',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司会计师”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“会计师”',
            materialInfo: [
              {
                type: '注册会计师证明',
                desc: [
                  '请提交注册会计师资格证书，清晰展示“姓名”、“专业方向”、“资格级别”和“管理号”等相关信息。',
                ],
              },
              {
                type: '其他职称证明',
                desc: [
                  '请提交由正规专业技术资格协会颁发的会计师职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格级别”和“管理号”等相关信息。',
                  '注：若职称证明中没有体现所需认证的企业信息，请在补充材料中上传例如“在职证明”、“劳务合同”或“聘书”等证明材料。',
                ],
              },
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_PROFESSION,
            tipText: '如“XX公司高级会计师”',
            materialInfo: [
              {
                type: '注册会计师证明和在职证明',
                desc: [
                  '1、请提交注册会计师资格证书，清晰展示“姓名”、“专业方向”、“资格级别”和“管理号”等相关信息。',
                  '2、请提交在3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '注册会计师证明和劳务合同',
                desc: [
                  '1、请提交注册会计师资格证书，清晰展示“姓名”、“专业方向”、“资格级别”和“管理号”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '其他职称证明',
                desc: [
                  '请提交由正规专业技术资格协会颁发的会计师职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格级别”和“管理号”等相关信息。',
                  '注：若职称证明中没有体现所需认证的企业信息，请在补充材料中上传例如“在职证明”、“劳务合同”或“聘书”等证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_PROFESSION,
            tipText: '如“高级会计师”',
            materialInfo: [
              {
                type: '注册会计师证明',
                desc: [
                  '请提交注册会计师资格证书，清晰展示“姓名”、“专业方向”、“资格级别”和“管理号”等相关信息。',
                ],
              },
              {
                type: '其他职称证明',
                desc: [
                  '请提交由正规专业技术资格协会颁发的会计师职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格级别”和“管理号”等相关信息。',
                  '注：若职称证明中没有体现所需认证的企业信息，请在补充材料中上传例如“在职证明”、“劳务合同”或“聘书”等证明材料。',
                ],
              },
            ],
          },
        ],
      },
      // {
      //   name: '审计师',
      //   desc: ['有效期内的审计专业技术中级资格证书'],
      //   id: '23',
      // },
      // {
      //   name: '精算师',
      //   desc: ['准精算师职业资格证或者正精算师职业资格证或精算师职业资格证'],
      //   id: '24',
      // },
      {
        name: '基金从业人员',
        id: '25',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司基金从业人员”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“基金从业人员”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '期货从业人员',
        id: '26',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司期货从业人员”',
            materialInfo: [
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国期货业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“任职部门”和“从业资格证号”等信息，链接：https://gs.amac.org.cn/。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国期货业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“任职部门”和“从业资格证号”等信息，链接：https://gs.amac.org.cn/。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“期货从业人员”',
            materialInfo: [
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国期货业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“任职部门”和“从业资格证号”等信息，链接：https://gs.amac.org.cn/。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  '1、请提交中国期货业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“任职部门”和“从业资格证号”等信息，链接：https://gs.amac.org.cn/。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '证券从业人员',
        id: '27',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司证券经纪人”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  ' 1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  ' 1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“证券经纪人”',
            materialInfo: [
              {
                type: '从业资格证和在职证明',
                desc: [
                  '1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '从业资格证和劳动合同',
                desc: [
                  ' 1、请提交由中国证券业协会颁布的有效期内的证券从业资格证 （SAC），需盖有公章，并清晰展示“姓名”、“身份证号”和“证书号码”等相关信息。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和在职证明',
                desc: [
                  '1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '个人资质截图和劳动合同',
                desc: [
                  ' 1、请提交中国证券业协会官网信息公示中个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://www.sac.net.cn。',
                  '2、请提交在中国证监会合法机构名录中单位任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '保险从业者',
        id: '28',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司保险销售经理”',
            materialInfo: [
              {
                type: '从业人员资质截图和在职证明',
                desc: [
                  '1、请提交由国家政务服务平台查询保险从业人员查询出的个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://cbirc.gjzwfw.gov.cn/。',
                  '2、请提交在在国家金融监督管理局备案的保险机构或保险中介机构任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '从业人员资质截图和劳动合同',
                desc: [
                  '1、请提交由国家政务服务平台查询保险从业人员查询出的个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://cbirc.gjzwfw.gov.cn/。',
                  '2、请提交在在国家金融监督管理局备案的保险机构或保险中介机构任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡',
                ],
              },
              {
                type: '监管系统截图和在职证明',
                desc: [
                  '1、请提交由保险中介监管系统查询出的个人资质截图，需清晰展示“姓名”、执业证编号、登记日期，链接：https://www.insurcloud.com.cn/。',
                  '2、请提交在在国家金融监督管理局备案的保险机构或保险中介机构任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '监管系统截图和劳动合同',
                desc: [
                  '1、请提交由保险中介监管系统查询出的个人资质截图，需清晰展示“姓名”、执业证编号、登记日期，链接：https://www.insurcloud.com.cn/。',
                  '2、请提交在在国家金融监督管理局备案的保险机构或保险中介机构任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“保险销售经理”',
            materialInfo: [
              {
                type: '从业人员资质截图和在职证明',
                desc: [
                  '1、请提交由国家政务服务平台查询保险从业人员查询出的个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://cbirc.gjzwfw.gov.cn/。',
                  '2、请提交在在国家金融监督管理局备案的保险机构或保险中介机构任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '从业人员资质截图和劳动合同',
                desc: [
                  '1、请提交由国家政务服务平台查询保险从业人员查询出的个人资质截图，需清晰展示“姓名”、“执业机构”、“执业岗位”和“登记编号”，链接：https://cbirc.gjzwfw.gov.cn/。',
                  '2、请提交在在国家金融监督管理局备案的保险机构或保险中介机构任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡',
                ],
              },
              {
                type: '监管系统截图和在职证明',
                desc: [
                  '1、请提交由保险中介监管系统查询出的个人资质截图，需清晰展示“姓名”、执业证编号、登记日期，链接：https://www.insurcloud.com.cn/。',
                  '2、请提交在在国家金融监督管理局备案的保险机构或保险中介机构任职的，3个月内开具的在职证明，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
              {
                type: '监管系统截图和劳动合同',
                desc: [
                  '1、请提交由保险中介监管系统查询出的个人资质截图，需清晰展示“姓名”、执业证编号、登记日期，链接：https://www.insurcloud.com.cn/。',
                  '2、请提交在在国家金融监督管理局备案的保险机构或保险中介机构任职的，结束时间大于半年的劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。',
                ],
              },
            ],
          },
        ],
      },
      // {
      //   name: '金融风险管理师',
      //   desc: [
      //     '金融风险管理师职业资格证书。需清晰展示个人信息。',
      //     '3 个月内开具的在职证明 或 有效期内的劳动合同：盖有单位章，内容需包含“职称、所在部门、入职时间、身份证号、姓名。',
      //   ],
      //   id: '29',
      // },
    ],
  },
  {
    name: '媒体',
    children: [
      {
        name: '主持人',
        id: '30',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX电台少儿节目主持人”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的由电视台、广播台等媒体机构在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '职称证明',
                desc: [
                  '请提交由正规部门或测评中心颁发的主持人/播音相关职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格级别”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“获奖证明”、“荣誉证明”、“聘书”、“参演证明”和“职称证明”等能够验证职位、任职单位、任职时间的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“少儿节目主持人”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的由电视台、广播台等媒体机构在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '职称证明',
                desc: [
                  '请提交由正规部门或测评中心颁发的主持人/播音相关职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格级别”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“获奖证明”、“荣誉证明”、“聘书”、“参演证明”和“职称证明”等能够验证职位、任职单位、任职时间的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_PROFESSION,
            tipText: '如“XX电台一级播音员主持人”',
            materialInfo: [
              {
                type: '职称证明',
                desc: [
                  '请提交由正规部门或测评中心颁发的主持人/播音相关职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格级别”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“获奖证明”、“荣誉证明”、“聘书”、“参演证明”和“职称证明”等能够验证职位、任职单位、任职时间的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_PROFESSION,
            tipText: '如“一级播音员主持人”',
            materialInfo: [
              {
                type: '职称证明',
                desc: [
                  '请提交由正规部门或测评中心颁发的主持人/播音相关职称证明，需盖有公章，并清晰展示“姓名”、“专业方向”、“资格级别”和“证书编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“获奖证明”、“荣誉证明”、“聘书”、“参演证明”和“职称证明”等能够验证职位、任职单位、任职时间的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '记者',
        id: '31',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX电台前线记者”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的由电视台、报社等媒体机构出示的全职在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '资格证证明',
                desc: [
                  '请提交由国家新闻出版广电总局或国家新闻出版署颁发的有效期内新闻记者证，需盖有公章，并清晰展示“姓名”、“身份证号”和“统一编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“公开报道链接”、“聘书”、“资格证书”和“职称证明”等能够验证所需认证字段的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“前线记者”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的由电视台、报社等媒体机构出示的全职在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '资格证证明',
                desc: [
                  '请提交由国家新闻出版广电总局或国家新闻出版署颁发的有效期内新闻记者证，需盖有公章，并清晰展示“姓名”、“身份证号”和“统一编号”等相关信息。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“公开报道链接”、“聘书”、“资格证书”和“职称证明”等能够验证所需认证字段的证明材料。',
                ],
              },
            ],
          },
        ],
      },

      // {
      //   name: '编辑',
      //   desc: [
      //     '出版专业职业资格证书。需清晰展示个人信息。',
      //     '责任编辑证书。需清晰展示个人信息。',
      //     '1个月内开具的在职证明或有效期内的劳动合同。需盖有单位公章，内容需包含“身份证号、姓名、入职时间、签署时间” 。 ',
      //   ],
      //   id: '32',
      // },
    ],
  },
  {
    name: '咨询',
    children: [
      {
        name: '咨询顾问',
        id: '33',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司就业咨询顾问”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。其中 在职证明 需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“就业咨询顾问”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和 公章 等信息清晰可见、无遮挡。其中 在职证明 需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: '房地产',
    children: [
      {
        name: '房地产经纪人',
        id: '34',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司房产客户经理”',
            materialInfo: [
              {
                type: '执业资格证、登记备案截图、在职证明',
                desc: [
                  '1、请提交由中国房地产估价师与房地产经纪人学会颁布的有效期内的国家房地产经纪专业人员职业资格证，需盖有公章，并清晰展示“姓名”、“证件号码”和“管理号”等相关信息。',
                  '2、请提交当地住建委或住建局或房屋管理局或全国房地产经纪行业平台完成登记备案的房地产经纪人登记备案截图。需清晰展示“个人信息”、“证书管理号”和“专业类别”。',
                  '3、请提交在3个月内由相关房产机构开具的可证明认证单位和职位属实的在职证明，需盖有单位公章，内容需包含“姓名”、“身份证号”、“入职时间” 和“签署时间”等相关信息。',
                ],
              },
              {
                type: '执业资格证、登记备案截图、劳动合同',
                desc: [
                  '1、请提交由中国房地产估价师与房地产经纪人学会颁布的有效期内的国家房地产经纪专业人员职业资格证，需盖有公章，并清晰展示“姓名”、“证件号码”和“管理号”等相关信息。',
                  '2、请提交当地住建委或住建局或房屋管理局或全国房地产经纪行业平台完成登记备案的房地产经纪人登记备案截图。需清晰展示“个人信息”、“证书管理号”和“专业类别”。',
                  '3、请提交结束时间大于半年的劳动合同，需盖有单位公章，内容需包含“姓名”、“身份证号”、“入职时间” 和“签署时间”等相关信息。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“房产客户经理”',
            materialInfo: [
              {
                type: '执业资格证和登记备案截图',
                desc: [
                  '1、请提交由中国房地产估价师与房地产经纪人学会颁布的有效期内的国家房地产经纪专业人员职业资格证，需盖有公章，并清晰展示“姓名”、“证件号码”和“管理号”等相关信息。',
                  '2、请提交当地住建委或住建局或房屋管理局或全国房地产经纪行业平台完成登记备案的房地产经纪人登记备案截图。需清晰展示“个人信息”、“证书管理号”和“专业类别”。',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'IT/互联网',
    children: [
      {
        name: '产品',
        id: '35',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司产品经理”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“产品经理”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '设计师',
        id: '36',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司设计师”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“设计师”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '软件工程师',
        id: '37',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司开发工程师”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“开发工程师”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '数据工程师',
        id: '38',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.ACCOUNT_AND_JOB,
            tipText: '如“XX公司大数据挖掘”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
          {
            type: AUTH_IDENTITY_TYPE.ONLY_JOB,
            tipText: '如“大数据挖掘”',
            materialInfo: [
              {
                type: '在职证明/劳务合同',
                desc: [
                  '请提交有效期内的在职证明/劳务合同，确保姓名、身份证号、公司名称、公司职位、入职时间 和公章等信息清晰可见、无遮挡。其中在职证明需在3个月内开具，劳务合同有效期需大于半年。',
                ],
              },
              {
                type: '其他证明材料',
                desc: [
                  '请提交包括但不限于“在职证明”、“劳务合同”等具备公章，且能够证明在职公司、目前职位、在职时效、以及其他认证信息的证明材料。',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const talentList: TPersonalAuth[] = [
  {
    name: '达人',
    children: [
      {
        name: '娱乐达人',
        id: '50',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.EXPERT,
            tipText: '如“XX公司演员”',
            materialInfo: [
              {
                type: '专业资质证明',
                desc: [
                  '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '知识达人',
        id: '51',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.EXPERT,
            tipText: '如“XX公司作家”',
            materialInfo: [
              {
                type: '专业资质证明',
                desc: [
                  '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '艺术达人',
        id: '52',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.EXPERT,
            tipText: '如“XX公司舞美”',
            materialInfo: [
              {
                type: '专业资质证明',
                desc: [
                  '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '游戏达人',
        id: '53',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.EXPERT,
            tipText: '如“王者荣耀大神”',
            materialInfo: [
              {
                type: '专业资质证明',
                desc: [
                  '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等材料。',
                ],
              },
            ],
          },
        ],
      },
      {
        name: '其他达人',
        id: '54',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.EXPERT,
            tipText: '如“XX达人”',
            materialInfo: [
              {
                type: '专业资质证明',
                desc: [
                  '请提供能够证明本人达人身份的资料，包括但不限于本人获奖证书、新闻相关报道、原创的创作资料、版权证明或创作过程证明等材料。',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: '赛事',
    children: [
      {
        name: '赛事选手',
        id: '55',
        tipConfig: [
          {
            type: AUTH_IDENTITY_TYPE.EVENT,
            tipText: '如“乒乓球国家队运动员”',
            materialInfo: [
              {
                type: '获奖证明',
                desc: [
                  '省市级及以上的获奖证书/奖杯/奖牌和奖项相关主流媒体报道链接。材料需体现获奖年份、赛事名称、获奖名次、真实姓名、证书颁发时间等信息，链接需体现赛事名称、获奖信息、真实姓名等信息。',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export type TFCS = {
  /** 材料名称 */
  name?: string;
  /** ocr信息 */
  ocr_info?: IOcrSingleInfo[];
  /** 材料地址 */
  url?: string;
};

/** 主要材料类 */
export type TCoreMaterial = {
  /** 主要材料 */
  url_list: TFCS[];
  /** 主要材料名称 */
  name?: string;
};

/** 验证码类型 */
export const enum VERIFY_TYPE {
  /** 手机号验证码 */
  MOBILE = 1,
  /** 邮箱验证码 */
  EMAIL = 2,
}

/** 验证码信息 */
export interface ISmsInfo {
  /** 验证码 */
  sms_code?: string;
  /** 本次验证码请求对应session */
  verify_session_id?: string;
  /** 验证类型 */
  verify_type?: VERIFY_TYPE;
  /** 第一步保存时获取到的id */
  job_certify_id?: string;
}

/** 认证方式配置类型 */
export type TMethodOption = {
  value: AUTH_METHOD;
  name: string;
  desc: string;
};

/** 认证方式 */
export enum AUTH_METHOD {
  /** 个人材料认证 */
  PERSONAL_MATERIAL_AUTH = 1,
  /** 微信名片认证 */
  WECHAT_BUSINESS_CARD_AUTH = 2,
  /** 站外平台认证 */
  OFF_SITE_AUTH = 3,
}

/** 认证方式对应的配置 */
export const AUTH_METHOD_OPTIONS: Record<AUTH_METHOD, TMethodOption> = {
  [AUTH_METHOD.PERSONAL_MATERIAL_AUTH]: {
    value: AUTH_METHOD.PERSONAL_MATERIAL_AUTH,
    name: '个人材料认证',
    desc: '适合所有用户',
  },
  [AUTH_METHOD.WECHAT_BUSINESS_CARD_AUTH]: {
    value: AUTH_METHOD.WECHAT_BUSINESS_CARD_AUTH,
    name: '企微名片认证',
    desc: '适合企业微信的用户',
  },
  [AUTH_METHOD.OFF_SITE_AUTH]: {
    value: AUTH_METHOD.OFF_SITE_AUTH,
    name: '站外平台认证',
    desc: '适合已站外认证的用户',
  },
};

/** 认证类型对应的认证方法 */
export const AUTH_TYPE_METHOD = {
  /** 达人认证对应的认证方法 */
  [AUTH_TYPE.KOL]: [AUTH_METHOD.PERSONAL_MATERIAL_AUTH, AUTH_METHOD.OFF_SITE_AUTH],
  /** 职业认证对应的认证方法 */
  [AUTH_TYPE.OCCUPATION]: [
    AUTH_METHOD.PERSONAL_MATERIAL_AUTH,
    AUTH_METHOD.WECHAT_BUSINESS_CARD_AUTH,
    AUTH_METHOD.OFF_SITE_AUTH,
  ],
};
