import { IDENTITY_TYPE } from 'common/constants/Types';
import { BLOCKED_ERROR_CODE, BLOCKED_ERROR_MSG } from 'idpBase/Constants';

export enum SSO_MODE {
  ORGANIZATION,
  EMAIL,
}

export enum SSO_STEP {
  ACCOUNT,
  LOGIN_IDENTITY,
}

export const LOGIN_ERR_MSG = {
  E0010041: '未找到匹配的企业', // 该企业未开启SSO
  E0010042: '未有可用的认证源，请联系管理员配置', // 开启SSO，但是无登录方式
  E0010048: '检测到多个匹配用户，登录失败！有问题请联系企业管理员。',
  E0010019: '未检测到匹配用户，登录失败！有问题请联系企业管理员。',
  E0010035: '检测到匹配用户已被停用，登录失败！有问题请联系企业管理员。',
  E0010049: '获取用户信息失败！有问题请联系企业管理员。',
  E0010053: '人员创建失败！有问题请联系企业管理员。',
  E0010007: '用户名或密码错误，登录失败。',
  E0010377: '密码错误次数过多，请稍后再试',
  E0010131:
    '该用户名匹配到多个用户，请使用 UserPrincipalName 或 sAMAccountName 作为用户名进行登录。',
  // AD认证源登录，用户不存在和停用会返回http status是400，然后对应errorCode是以下：
  E0010167: '用户不存在，请联系管理员',
  E0010165: '用户名或密码输入有误',
  // AD认证源登录，JIT创建失败，errorCode对应文案
  E0010179: '人员创建失败。联系邮箱与企业内成员重复，请联系管理员。',
  E0010020: '人员创建失败。您的联系手机号与企业内成员重复，请联系管理员。',
  E0010162: '人员创建失败。您的登录用户名与企业内成员重复，请联系管理员。',
  [BLOCKED_ERROR_CODE]: BLOCKED_ERROR_MSG[BLOCKED_ERROR_CODE],
};

export const REDIRECT_IDP_TYPES = [
  IDENTITY_TYPE.LARK,
  IDENTITY_TYPE.OIDC,
  IDENTITY_TYPE.SAML,
  IDENTITY_TYPE.CAS,
  IDENTITY_TYPE.DINGTALK,
  IDENTITY_TYPE.OAUTH2,
  IDENTITY_TYPE.JWT,
];

/** 认证流类型 */
export enum FLOW_TYPE {
  /** 使用oneid登录页 */
  DELEGATION = 'Delegation',
  /** 跳转到三方认证源登录页 */
  REDIRECT = 'Redirect',
}

// 不需要stateId的登录方式
export const LOGIN_WITHOUT_STATE_TYPES = [IDENTITY_TYPE.AD];

export const ERROR_MSG = {
  E0010035: '您在当前企业已被停用，请联系管理员',
  E0010125: '请使用正确的企业微信账号进行登录',
  E0010127: '您的企业微信未绑定身份，请先前往账户设置完成绑定',
  E0010129: '请使用正确的企业微信账号进行登录',
};
